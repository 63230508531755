import { yupResolver } from '@hookform/resolvers/yup';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { GridRowParams } from '@material-ui/data-grid';
import { customAlphabet } from 'nanoid';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ConfirmButton } from '~/components/Buttons';
import DefaultLoader from '~/components/DefaultLoader';
import FormDefault from '~/components/FormDefault';
import { InputText } from '~/components/NovosInputs';
import Search from '~/components/Search';
import ToggleDefault from '~/components/ToggleDefault';
import api, { getAPIUrl } from '~/services/api';

import clear from './defaultData/clear.json';
import { Loja } from './protocols';
import { Container } from './styles';
import { copyToClipboard } from './utils/copyToClipboard';
import { schema } from './validations';

const nanoid = customAlphabet(
  '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
  45,
);

const LiberacaoDeToken: React.FC = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState<boolean>(true);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [flagInativo, setFlagInativo] = useState<boolean>(false);
  const [lojas, setLojas] = useState<Loja[]>([]);

  const apiURL = getAPIUrl(String(process.env.REACT_APP_BASE_URL));
  const urlIntegracao = `${apiURL}/integrador`;

  console.log(process.env); /* @TODO REMOVE */

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    // Set hash value to input
    setValue('hash', nanoid());
  }, [setValue]);

  useEffect(() => {
    setValue('url_integracao', urlIntegracao);
  }, [setValue, urlIntegracao, showSearch]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/lojas/usuario`);
      if (data.success) {
        const options = data.data.map((loja: any) => ({
          cod_loja: loja.cod_loja,
          des_loja: loja.des_loja,
          checked: false,
        }));
        setLojas(options);
      }
    })();
  }, []);

  const onRowClick = async (param: GridRowParams) => {
    setLoader(true);
    const { row } = param;
    setValue('cod_integracao', row.cod_integracao);
    setValue('des_integracao', row.des_integracao);
    setValue('url_integracao', urlIntegracao);
    setValue('hash', row.hash);
    setValue('inativo', row.flg_inativo);
    setFlagInativo(row.flg_inativo);

    // seleciona lojas
    const { data } = await api.post('/liberacao-tokens-lojas', {
      cod_integracao: row.cod_integracao,
    });
    if (data.success) {
      const idLojas: any[] = data.data.map((loja: any) => loja.cod_loja);
      setLojas((oldLojas) =>
        oldLojas.map((loja) => {
          if (idLojas.includes(loja.cod_loja)) {
            loja.checked = true;
          }
          return loja;
        }),
      );
    }
    setShowSearch(false);
    setIsUpdate(true);
    setLoader(false);
  };

  const resetFormData = useCallback(() => {
    setLoader(true);
    reset(clear);
    setLojas((oldLojas) =>
      oldLojas.map((loja) => {
        loja.checked = false;
        return loja;
      }),
    );
    // Set hash value to input
    setValue('hash', nanoid());
    setValue('url_integracao', urlIntegracao);
    setFlagInativo(false);
    setLoader(false);
  }, [reset, setValue, urlIntegracao]);

  const onSubmit = handleSubmit(async (formData) => {
    const selectedStores = lojas.filter((loja) => loja.checked);

    if (selectedStores.length <= 0) {
      toast.warning('Selecione ao menos uma loja para prosseguir.');
      return;
    }

    const { des_integracao, hash, inativo } = formData;
    const trimmedDesIntegracao = des_integracao.trim();

    if (trimmedDesIntegracao === '') {
      toast.warning('É necessário informar uma descrição.');
      return;
    }

    const integracaoLojas = selectedStores;

    try {
      let response;

      if (isUpdate) {
        response = await api.put('/liberacao-tokens', {
          cod_integracao: getValues('cod_integracao'),
          des_integracao: trimmedDesIntegracao,
          flg_inativo: inativo,
          lojas: integracaoLojas,
        });
      } else {
        response = await api.post('/liberacao-tokens', {
          des_integracao: trimmedDesIntegracao,
          hash,
          flg_inativo: inativo,
          lojas: integracaoLojas,
        });
      }

      if (response.data.success) {
        toast.success(response.data.message);
        resetFormData();

        if (isUpdate) setShowSearch(true);
        else setShowSearch(false);
      } else {
        toast.error(response.data.message);
      }
    } finally {
      setLoader(false);
    }
  });

  const onDelete = useCallback(() => {
    setLoader(true);
    setLoader(false);
  }, []);

  const onNew = () => {
    setIsUpdate(false);
    resetFormData();
    setShowSearch(false);
  };

  if (loader) {
    return <DefaultLoader />;
  }

  return (
    <Container>
      {showSearch && (
        <Search
          codTela={202}
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
        />
      )}
      {!showSearch && (
        <FormDefault
          codTela={202}
          title="Central De Liberação de Tokens"
          codigoRegistro={[
            {
              value: getValues('cod_integracao'),
              des_campo: 'Código',
            },
          ]}
          onSave={onSubmit}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={() => onNew()}
          onDelete={onDelete}
          onClearFields={() => resetFormData()}
          onReturnSearch={() => {
            setShowSearch(true);
            setIsUpdate(false);
          }}
          showSwitch={false}
          isDelete={false}
        >
          <div className="row">
            <Col sm={12} md={10}>
              <InputText
                label="Nome da Intergração"
                maxLength={100}
                placeholder="Informe o Nome da Integração"
                name="des_integracao"
                toLowerCase={false}
                caseInput="upper"
                control={control}
                register={register}
                disabled={false}
                isError={!!errors.des_integracao}
              />
            </Col>
            <Col sm={12} md={2}>
              <ToggleDefault
                labelText="Inativo?"
                setChecked={flagInativo}
                onSwitch={() => {
                  setValue('inativo', !flagInativo);
                  setFlagInativo(!flagInativo);
                }}
              />
            </Col>
            <Col sm={12} md={9}>
              <InputText
                label="URL Intergração"
                maxLength={100}
                placeholder=""
                name="url_integracao"
                id="url_integracao"
                register={register}
                disabled
                isError={!!errors.url_integracao}
              />
            </Col>
            <Col sm={12} md={2} className="confirmbutton">
              <ConfirmButton
                onClick={() => copyToClipboard('url_integracao')}
                style={{ marginTop: '1.875rem' }}
              >
                COPIAR
              </ConfirmButton>
            </Col>
            <Col sm={12} md={9}>
              <InputText
                label="Hash"
                maxLength={100}
                placeholder=""
                name="hash"
                id="hash"
                register={register}
                disabled
                isError={!!errors.hash}
              />
            </Col>
            <Col sm={12} md={2} className="confirmbutton">
              <ConfirmButton
                onClick={() => copyToClipboard('hash')}
                style={{ marginTop: '1.875rem' }}
              >
                COPIAR
              </ConfirmButton>
            </Col>
          </div>
          <div className="row" style={{ marginTop: '40px' }}>
            <Col sm={12}>
              <TableContainer style={{ maxHeight: 250 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Acesso?</TableCell>
                      <TableCell>Loja</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lojas.map((loja) => {
                      const { checked } = loja;
                      return (
                        <TableRow key={loja.cod_loja}>
                          <TableCell>
                            <Form.Check
                              style={{ margin: 'auto' }}
                              type="checkbox"
                              id="default-"
                              title="teste"
                              aria-label={`${loja.cod_loja} - ${loja.des_loja}`}
                              checked={checked}
                              onChange={() => {
                                setLojas((oldLojas) =>
                                  oldLojas.map((currentLoja) => {
                                    if (
                                      currentLoja.cod_loja === loja.cod_loja
                                    ) {
                                      currentLoja.checked = !checked;
                                    }
                                    return currentLoja;
                                  }),
                                );
                              }}
                              size={5}
                            />
                          </TableCell>
                          <TableCell>{`${loja.cod_loja} - ${loja.des_loja}`}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Col>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default LiberacaoDeToken;
